.ApiRoute {
  text-align: left;
  margin: 1vmin;
}

.swagger-ui div.information-container {
  display: none;
}

.swagger-ui section.models {
  display: none;
}